(function ($) {
  Drupal.behaviors.mppSort = {
    attach: function () {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $trigger = $('.js-mpp-sort-menu');
      var sortFns = {
        price_low_to_high: function (prod) {
          if (prod) {
            return prod.defaultSku['PRICE'];
          }
        },
        price_high_to_low: function (prod) {
          if (prod) {
            return prod.defaultSku['PRICE'] * -1;
          }
        },
        display_order: 'displayOrder',
        name_a_z: 'PROD_RGN_NAME',
        reviews: 'AVERAGE_RATING',
        newest: 'AVERAGE_RATING' // field name yet to be updated from Tomek's response
      };

      $trigger.on('change', function (e) {
        e.preventDefault();
        var $sortMenu = $(this);
        var val = $sortMenu.val();
        var sortFn = sortFns[val];

        $(document).trigger('mpp_sort:selected', [sortFn]);
      });
    },
    attached: false
  };
})(jQuery);
